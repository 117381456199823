import * as React from "react"
import { BusinessContactForm } from '../components/businessContactForm'

const BusinessContact = () => {
  return (
    <>
      <head>
          <title>Fidize - Business contact</title>
      </head>
      <BusinessContactForm />
    </>
  )
}


export default BusinessContact
