import React from 'react';
import { Hpadding } from '@fidize/ui.hpadding';
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Flex } from '@fidize/ui.flex';
import { Text } from '@fidize/ui.text';
import { Button } from '@fidize/ui.button';
import { Hr } from '@fidize/ui.hr';
import { Link } from 'gatsby'
import { Input } from '../input'
import { Textarea } from '../textarea'

export class BusinessContactForm extends React.Component{
  state = {
    name: "",
    email: "",
    tel: "",
    company: "",
    piva: "",
    sdi: "",
    ncard: "",
    mex: "",

    btnDisabled: false,
    forced: false
  }

  setValue = (dest, val) => {
    var j = {}
    j[dest] = val
    this.setState(j)
  }

  isValid = (dest) => {
    if(this.state[dest].length == 0 & !this.state.forced)
      return true
    if(dest == "email"){
      return RegExp(/^\S+@\S+\.\S+$/).test(this.state.email);
    }else{
      return this.state[dest].length > 0;
    }
  }

  checkAll = () => {
    var toCheck = ["name", "email", "company", "piva", "sdi", "ncard"];
    this.state.forced = true;
    var valid = toCheck.reduce((tot, k) => {
      if(!tot)
        return false
      return this.isValid(k)
    }, true)

    if(!valid)
      window.toastError("Attenzione, compilare tutti i campi obbligatori.")
    return valid;
  }

  sendEmail = async () => {
    this.setState({btnDisabled: true})
    console.log(this)

    if(this.checkAll()){
      var j = {
        name: this.state.name,
        email: this.state.email,
        tel: this.state.tel,
        company: this.state.company,
        piva: this.state.piva,
        sdi: this.state.sdi,
        ncard: this.state.ncard,
        obj: "✓ Contatto aziendale",
        mex: this.state.mex
      }

      var response = await fetch('/api/send_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(j)
      });
      var j = await response.json();
      if(j.status == 200){
        window.toastSuccess("Email inviata con successo. Riceverai risposta nelle prossime ore.")
        this.setState({btnDisabled: true})
        return;
      }
      window.toastError("Errore nell'invio. Tetare tra alcuni minuti o contattare info@fidize.com.")
    }
    //this.setState({btnDisabled: false})
  }

  render(){
    var desk =  <Hpadding padding="2">
                  <Vspace desk="60px" mobile="30px" />
                  <Flex type="justify_center">
                    <Text type="h2 black">Richiesta preventivo per aziende</Text>
                  </Flex>
                  <Vspace desk="20px" mobile="10px" />
                  <Flex type="justify_center">
                    <Text type="normal">Personalizza la Card dopo aver completato l'acquisto.</Text>
                  </Flex>
                  <Vspace desk="70px" mobile="30px" />
                  <Flex type="column">
                    <Input placeholder="Nome" value={this.state.name} destination="name" onChange={this.setValue} valid={this.isValid("name")}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Input placeholder="Email" value={this.state.email} destination="email" onChange={this.setValue} valid={this.isValid("email")}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Input placeholder="Telefono" value={this.state.tel} destination="tel" onChange={this.setValue}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Input placeholder="Azienda" value={this.state.company} destination="company" onChange={this.setValue} valid={this.isValid("company")}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Input placeholder="P.IVA" value={this.state.piva} destination="piva" onChange={this.setValue} valid={this.isValid("piva")}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Input placeholder="SDI" value={this.state.sdi} destination="sdi" onChange={this.setValue} valid={this.isValid("sdi")}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Input placeholder="Numero carte" value={this.state.ncard} destination="ncard" onChange={this.setValue} valid={this.isValid("ncard")}></Input>
                    <Vspace desk="20px" mobile="10px" />
                    <Textarea placeholder="Messaggio" value={this.state.mex} destination="mex" onChange={this.setValue}></Textarea>
                    <Vspace desk="60px" mobile="30px" />

                    <Flex type="row justify_center">
                      <Button onClick={this.sendEmail} type="blue" style={{padding: "20px 50px"}} disabled={this.state.btnDisabled}>
                        <Text type="normal bold white">Richiedi un preventivo</Text>
                      </Button>
                    </Flex>

                    <Vspace desk="100px" mobile="60px" />
                  </Flex>
                </Hpadding>

    var mobile =  <Hpadding padding="mobile0">
                  <Vspace desk="60px" mobile="30px" />
                  <Flex type="justify_center">
                    <Text type="h2 black">Richiesta preventivo per aziende</Text>
                  </Flex>
                  <Vspace desk="50px" mobile="20px" />
                  <Flex type="justify_center">
                    <Text type="h4 nobold">Personalizza la Card dopo aver completato l'acquisto.</Text>
                  </Flex>
                  <Vspace desk="40px" mobile="40px" />
                  </Hpadding>

    return (
      <Responsive desk={desk} mobile={mobile} />
    );
  }
}
