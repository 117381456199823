import React from 'react';
import styles from './input.module.scss';
import { Responsive } from "@fidize/ui.responsive"

export class Input extends React.Component {

  setValue = (e) => {
    var new_val = e.target.value
    if(this.props.hasOwnProperty("destination")){
      this.props.onChange(this.props.destination, new_val)
    }
  }

  render(){
      var valid = this.props.hasOwnProperty("valid") ? this.props.valid : true;
      return ( <input
                  type="text"
                  value={this.props.value}
                  className={styles.input + (valid ? "" : " " + styles.invalid)}
                  onChange={this.setValue}
                  placeholder={this.props.placeholder} />
              );
  }
}
